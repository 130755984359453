<template>
    <div class="dashboard-body-content d-flex justify-content-center align-items-center">
        <ul class="dashboard-quick-nav">
            <li class="nav-item">
                <router-link :class="`nav-link _quotation`" to="/">
                    <span class="link-icon"><i
                            class="fas fa-file-alt"></i><span class="badge"><i class="fa fa-plus"></i> </span></span>
                    <span class="link-text">
                        Quotation
                    </span>
                </router-link>
            </li>
            <li class="nav-item"><a href="#" class="nav-link _invoice"><span class="link-icon"><i
                            class="fas fa-file-alt"></i><span class="badge"><i class="fa fa-plus"></i> </span></span>
                    <span class="link-text">Invoice</span> </a> </li>
            <li class="nav-item"><a href="#" class="nav-link _expense"><span class="link-icon"><i
                            class="fas fa-file-alt"></i><span class="badge"><i class="fa fa-plus"></i> </span></span>
                    <span class="link-text">Expense</span> </a> </li>
        </ul>
    </div>
</template>
<script>
    export default {
        name: 'Dashboard'
    }
</script>