<template>
    <div>
        <section class="container-fluid">
            <div class="bg-pattern overlay-soleCo logReg-container">
                <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-4 mx-auto">
                    <!--login form block start-->
                    <div class="card card-form sign-upIn _compType">
                        <div class="card-header">
                            <div class="card-title">
                                Select one
                            </div>
                        </div>
                        <div class="card-body">
                            <form class="base-form h-100 d-flex justify-content-between flex-column">
                                <h5 class="form-title">Are you a sole  trader or a limited company?</h5>
                                <div class="d-flex flex-column h-100 align-items-center justify-content-around mt-4">
                                    <a href="#" class="btn btn-indigo btn-xl">Sole Trader</a>
                                    <a href="#" class="btn btn-primary btn-xl">Limited Company</a>
                                </div>
                                <div class="form-footer d-flex align-items-end">
                                </div>
                            </form>
                        </div>
                    </div>
                    <!--ended login form block-->
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: "SoleTraderLimitedCompany"
}
</script>