import { createWebHistory, createRouter } from "vue-router";
import Login from "../components/auth/Login";
import ForgotPassword from "../components/auth/ForgotPassword";
import Register from "../components/auth/Register";
import Regional from "../components/auth/Regional";
import UserDashboard from "../components/user/Dashboard";
import AuthMiddleware from '../middleware/auth.middleware';
import LandingPage from '../components/Landing';
import SoleTraderLimitedCompany from '../components/user/SoleTraderLimitedCompany';
import PasswordConfirmation from '../components/auth/PasswordConfirmation';
import Logout from "../components/auth/Logout";
import store from '../store'

const routes = [
  {
    path: "/",
    name: "home",
    component: LandingPage,
    meta: { 
      layout: 'login' 
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { 
      layout: 'login' 
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: { 
      layout: 'login' 
    },
  },
  {
    path: "/password-confirmation",
    name: "password-conrimation",
    component: PasswordConfirmation,
    meta: { 
      layout: 'login' 
    },
  },
  {
    path: "/regional-settings",
    name: "regional-settings",
    component: Regional,
    meta: { 
      layout: 'login' 
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-passoword",
    component: ForgotPassword,
    meta: { 
      layout: 'login' 
    },
  },
  {
    path: "/dashboard",
    name: "user-dashboard",
    component: UserDashboard,
    meta: { 
      middleware: AuthMiddleware,
      layout: 'user' 
    },
  },
  {
    path: "/select",
    name: "soletrader-limitedcompany",
    component: SoleTraderLimitedCompany,
    meta: { 
      middleware: AuthMiddleware,
      layout: 'login' 
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  let routes = ["login", "register", "regional-settings", "home", "forgot-password"];
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  } else if(routes.includes(to.name)) {
    if (store.getters['auth/getLoggedIn']) {
      next({ name: 'user-dashboard' })
    }
    // router.push('dashboard')
  }

  return next();
});

export default router;