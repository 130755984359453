<template>
    <div>
        <!--splash screen container-->
        <section class="container-fluid">
            <section class="splash-container">
                <div class="splash-content">
                    <div class="splash-thumb">
                        <img src="../assets/images/system/Circle.webp"/>
                    </div>
                    <div class="enter-inside">
                        <router-link to="/login">Enter <i class="fas fa-angle-double-right"></i></router-link>
                    </div>
                </div>
                <div class="splash-footer">
                    <div class="sub-logo">
                        <img src="../assets/images/system/Cat.svg"/>
                    </div>
                    <div class="footer-info">
                        <h4 class="txt-primary">iOS, Android and Web</h4>
                        <h5>Quotation, Invoice and Expense management</h5>
                    </div>
                </div>
            </section>
        </section>
        <!--ended splash screen container-->
    </div>
</template>
<script>
export default {
    name: 'Landing',
    setup() {
        
    },
}
</script>