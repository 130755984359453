<template>
    <div>
        <!--header start-->
        <header class="header fixed-top">
            <nav class="header-navBar">

                <div class="header-toggleBar">

                    <!--<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>-->

                    <button type="button" class="btn btn-hamburger" data-bs-toggle="collapse" data-bs-target="#dashboardSidebar" aria-controls="dashboardSidebar" aria-expanded="true" aria-label="Dashboard Sidebar">
                        <span class="line start"></span>
                        <span class="line center"></span>
                        <span class="line end"></span>
                    </button>
                    <a href="#" class="nav-linkHome">
                        <img :src="homeSrc" alt="my-logo" />
                    </a>
                </div>
                <div class="header-navBar-menus">

                    <ul class="navbar-social">
                        <li class="nav-item">
                            <a class="nav-link no-border alert" href="#"><i class="fas fa-share-alt"></i> </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#"><i class="fab fa-facebook-f"></i> </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#"><i class="fab fa-twitter"></i> </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#"><i class="fas fa-envelope"></i> </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#"><i class="fas fa-comment"></i> </a>
                        </li>
                    </ul>

                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item">
                            <a class="nav-link notification" href="#"><i class="fa fa-bell"></i> <span
                                    class="badge badge-count">89</span>
                            </a>
                        </li>

                        <li class="nav-item dropdown logedUser">
                            <a class="nav-link dropdown-toggle logedInfo" href="#" id="navbarDropdown" role="button"
                               data-bs-toggle="dropdown" aria-expanded="false">

                                <div class="hexagon hexagon1">
                                    <div class="hexagon-in1">
                                        <div class="hexagon-in2">
                                            <div class="logedAvatar">
                                                <img src="@/assets/images/system/user-F.png"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span v-if="this.$store.state.auth.status.loggedIn">
                                    Hello, {{ welcome_name }}
                                </span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="#">Dashboard</a></li>
                                <li><a class="dropdown-item" href="#">Profile</a></li>

                                <li><a class="dropdown-item" href="#">Settings</a></li>
                                <li>
                                    <router-link to="/logout" :class="`dropdown-item`">Sign out <img
                                        src="@/assets/images/system/logout.svg" class="append"/></router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
        <!--header end-->


        <!--dashboard container start-->
        <section class="dashboard-container collapse show" id="dashboardSidebar">
            <!--dashboard left side bar start-->
            <aside class="dashboard-sidebar">
                <div class="dashboard-menuWrap justify-content-between">
                    <div class="menu-row">
                        <ul class="dashboard-nav">
                            <li class="nav-item">
                                <a href="#" class="nav-link nav-header active"><span class="prepend"><img src="@/assets/images/system/folder-icon.svg"/></span> Folder</a>
                                <ul class="dashboard-subNav">
                                    <li class="nav-item"><a href="#" class="nav-link"><span class="prepend"><span class="dot _flamingo"></span> </span> Quotation </a></li>
                                    <li class="nav-item"><a href="#" class="nav-link"><span class="prepend"><span class="dot _java"></span></span>Invoice</a></li>
                                    <li class="nav-item"><a href="#" class="nav-link"><span class="prepend"><span class="dot _boston"></span></span>Expense</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="menu-row">
                        <ul class="navbar-nav dashboard-nav _v2">
                            <li class="nav-item"><a href="#" class="nav-link"><span class="prepend"><img src="@/assets/images/system/tools.svg"/> </span> Item list</a> </li>
                            <li class="nav-item"><a href="#" class="nav-link"><span class="prepend"><img src="@/assets/images/system/groups.svg"/> </span> Client list</a> </li>
                            <li class="nav-item"><a href="#" class="nav-link"><span class="prepend"><img src="@/assets/images/system/plus-minus.svg"/> </span> Accountant</a> </li>
                        </ul>
                    </div>

                    <div class="menu-row">
                        <ul class="navbar-nav dashboard-nav fl-bdr _v2">
                            <li class="nav-item"><a href="#" class="nav-link"><span class="prepend"><img src="@/assets/images/system/settings.png"/> </span>Settings</a> </li>
                            <li class="nav-item"><a href="#" class="nav-link"><span class="prepend"><img src="@/assets/images/system/cart.png"/> </span>Subscribe</a> </li>
                            <li class="nav-item"><a href="#" class="nav-link"><span class="prepend"><img src="@/assets/images/system/down-arrow.png"/> </span>Download</a> </li>
                            <li class="nav-item"><a href="#" class="nav-link"><span class="prepend top-pos"><img src="@/assets/images/system/medal.png"/> </span>Rate Us</a> </li>
                        </ul>
                    </div>
                </div>
            </aside>
            <!--dashboard left sidebar ended-->
            <!--dashboard main body start-->
            <div class="dashboard-body">
                <!-- view -->
                <router-view/>
            </div>
            <!--dashboard main body end-->
        </section>
        <!--ended dashboad container-->
    </div>
</template>
<script>
  // Please just use `@` to refer to the root "src" directory of the project
  import homeSrc from "@/assets/images/system/home.png";

  export default {
    name: "UserDashboard",
    data() {
        return {
            user: this.$store.state.auth.user,
            welcome_name: ""
        };
    },
    mounted() {
       this.welcome_name = this.$store.state.auth.user.welcomename;
    },
    created() {
    },
    setup() {
      return {
        homeSrc
      };
    }
  };
</script>