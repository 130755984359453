<template>
  <component :is="layout">
  </component>
</template>

<script>
import LoginLayout from "./components/layouts/auth";
import UserDashboardLayout from "./components/layouts/userDashboard";
// import DashboardLayout from "@/components/DashboardLayout";
export default {
  name: 'App',
  components: {
    LoginLayout,
    UserDashboardLayout
  },
  computed: {
    layout () {
      switch (this.$route.meta.layout) {
        case "login":
          return LoginLayout;
        case "user":
          return UserDashboardLayout;
        default:
          return LoginLayout;
      }
    }
  }
}
</script>
<style lang="scss">
// @import '~mdb-ui-kit/css/mdb.min.css';
// #app {
//   font-family: Roboto, Helvetica, Arial, sans-serif;
// }
</style>
<style scoped>
@import './assets/bootstrap/css/bootstrap.css';
@import './assets/css/sb_masterLayout.css';
</style>
