<template>
    <div>
        <!--splash screen container-->
        <section class="container-fluid">
            <div class="bg-pattern overlay-fppc logReg-container">
                <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-4 mx-auto">
                    <!--password reset confirmation block start-->
                    <div class="card card-form sign-upIn">
                        <div class="card-header">
                            <div class="card-title">
                                Forgot Password
                            </div>
                        </div>
                        <div class="card-body">
                            <form class="base-form" @submit.prevent="submitHandler">
                                <h6 class="form-title">Confirmation</h6>

                                <div class="block-ntfmsg">
                                    <p>Thank you.<br/>
                                        Instruction to reset your password has been sent. Please check your mailbox.</p>
                                </div>

                                <div class="form-footer align-items-end">
                                    <button type="submit" name="signIn" class="btn btn-success">Close</button>
                                </div>
                            </form>
                        </div>

                    </div>
                    <!--password reset confirmation block end-->

                </div>
            </div>
        </section>
        <!--ended splash screen container-->
    </div>
</template>
<script>
import { useRouter } from 'vue-router'

export default {
    name: "PasswordConfirmation",
    setup() {
        const router = useRouter()
        
        const submitHandler = () => {
            router.push("login")
        };

        return {submitHandler};
    },
}
</script>