import { USER_LOGIN, USER_REGISTRATION, FORGOT_PASSWORD } from '../config/url';
import axios from "axios";

class AuthService {
  login(user) {
    return axios
      .post(USER_LOGIN, {
        email: user.email,
        password: user.password,
        version: "web"
      })
      .then(response => {
        if (response.data.api_token) {
          // localStorage.setItem('user', JSON.stringify(response.data.response));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(USER_REGISTRATION, {
      fullname: user.fullname,
      email: user.email,
      password: user.password,
      country: user.country,
      currency: user.currency,
      version: "web",
      language: user.language
    }).then((res) => {
      return res;
    }).catch(error => {
      throw new Error(error)
    });
  }

  forgotPassword(user) {
    return axios.post(FORGOT_PASSWORD, {
      email: user.email
    }).then((res) => {
      return res;
    }).catch(error => {
      throw new Error(error)
    });
  }
}

export default new AuthService();