import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from 'vue-axios';
import Toaster from '@meforma/vue-toaster';
import 'bootstrap';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = '*';
// import * as mdb from 'mdb-ui-kit'; // lib
createApp(App)
.use(router)
.use(store)
.use(Toaster)
.use(VueAxios, axios)
// .use(mdb)
.mount("#app");