<template>
    <div>
        <section class="container-fluid">
            <div class="bg-pattern overlay-fppc logReg-container">
                <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-4 mx-auto">

                    <!--forgot password block start-->
                    <div class="card card-form sign-upIn">
                        <div class="card-header">
                            <div class="card-title">
                                Forgot password
                            </div>
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="handleSubmit" class="base-form">
                                <h6 class="form-title">Enter registered email address</h6>

                                <div class="input-group _ig-transparent mb-3">
                                    <span class="input-group-text"><i class="fa fa-user"></i> </span>
                                    <input type="text" v-model="form.email" id="reg_email" name="reg_email" class="form-control" placeholder="Enter yor email" required/>
                                </div>
                                <span class="form-text">If the provided information is correct, we will send you an instruction to reset your password</span>

                                <div class="form-footer align-items-end">
                                    <button type="submit" name="signIn" class="btn btn-success">Next</button>
                                </div>
                            </form>
                        </div>

                    </div>
                    <!--ended forgot password-->

                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name:"ForgotPassword",
    data() {
      return {
          form: {
              email: "",
          }
      }
    },
    methods: {
        handleSubmit() {
            this.$store.dispatch('auth/forgotPassword', this.form).then(res => {
                if (res.status == 1) {
                    this.$toast.success(res.message);
                    this.form.email = "";
                    this.$router.push("password-confirmation");
                } else {
                    throw new Error(JSON.stringify(res))
                }
            }).catch(err => {
                if (err.message) {
                    this.$toast.error(JSON.parse(err.message).message[0]);
                    return;
                }
                this.$toast.error('Something went wrong. Please try again.');
            });
        }
    }
}
</script>